import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import PrivacyPolicyContainer from "../../containers/PrivacyPolicyContainer";
import PageBanner from "../../sections/PageBanner";
import Section from "../Section";
import MarkdownToHtml from "../MarkdownToHtml";

const PrivacyPolicyPage = () => (
  <PrivacyPolicyContainer>
    {(privacyPolicyData) => {
      const pageName = "privacy_policy";
      const privacyPolicyContent = privacyPolicyData
        .map((ele) => (ele.id === pageName ? ele : null))
        .find((el) => el);

      const { backgroundImage, content, pageTitle } = privacyPolicyContent;

      return (
        <Box>
          {backgroundImage && pageTitle && (
            <PageBanner
              bannerContent={{ heading: pageTitle }}
              bgImage={backgroundImage}
            />
          )}
          <Section>
            {content && (
              <MarkdownToHtml
                source={content}
                sx={{
                  a: {
                    "&:hover": {
                      color: "catalinaBlue",
                    },
                    color: "primary",
                  },
                  h2: {
                    fontSize: 8,
                    mt: 4,
                  },
                  h3: {
                    color: "black",
                    fontSize: 3,
                    letterSpacing: "unset",
                    mt: 3,
                    textTransform: "unset",
                  },
                }}
              />
            )}
          </Section>
        </Box>
      );
    }}
  </PrivacyPolicyContainer>
);

PrivacyPolicyPage.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),
};

PrivacyPolicyPage.defaultProps = {
  location: null,
};

export default PrivacyPolicyPage;
