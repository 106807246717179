import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const PrivacyPolicyContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allPrivacyPolicyJson {
          edges {
            node {
              content
              id
              pageTitle
              backgroundImage
            }
          }
        }
      }
    `}
    render={({ allPrivacyPolicyJson: { edges: privacyPolicyData } }) =>
      children(
        privacyPolicyData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

PrivacyPolicyContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default PrivacyPolicyContainer;
