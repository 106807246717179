import React from "react";
import { Router } from "@reach/router";
import NotFoundPage from "./404";
import PrivacyPolicyPage from "../components/PrivacyPolicyPage";

const privacyPolicyPageRouter = (props) => (
  <Router>
    <PrivacyPolicyPage {...props} path="/privacy-policy" />
    <NotFoundPage default />
  </Router>
);

export default privacyPolicyPageRouter;
